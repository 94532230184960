import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const MintEcoShopProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Mint Eco Shop.<br />
              Inspired locally.<br />
              Manufactured in China.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              The renowned Western Australian based Mint Eco Shop, known for its commitment to sourcing products that are cruelty-free and eco-friendly, was looking to develop their own brand of bamboo-based products, called Wombat.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
            Here's how we made amazing happen for Mint Eco Shop.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/mint-eco-1.webp"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$20K</span>
            </p>
            <p className="font-black text-xl">ORDER VALUE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8593;7,700</span>
            </p>
            <p className="font-black text-xl">UNITS DELIVERED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>{'<'}1%</span>
            </p>
            <p className="font-black text-xl">DEFECT RATE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;18%</span>
            </p>
            <p className="font-black text-xl">COST SAVINGS</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/mint-eco-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            The power of language
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Mint Eco has been providing quality eco-friendly products for several years. All products have been sourced ethically, environmentally and cruelty free by researching the factories extensively. However, for their new ambitious project to source bamboo material for their new Wombat range, they found communication difficult with factories and agents overseas as well as time zones.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Enter Sourci. Our China office of researchers engaged with the factories directly, speaking the native language, sourced the bamboo and got certifications to ensure the products were environmentally friendly and cruelty free by completing extensive research into the manufacturing facilities.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Go ethical, organic, rainbow sparkly. Order 10, 100, 5000… a gazillion.
            <span className="block text-sourci-pink">Hit the start button.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Cargo consolidation reducing freight costs
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Leaning on the experience of Sourci, Brenda and Mint Eco were able to mitigate further complications by finding an experienced factory with a history of low defect rates to drive down incidental costs. By facilitating the entire end-to-end process, Brenda had cost savings across the supply chain and moved her freight into cargo consolidation, meaning we combined multiple shipments into one shipping container.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With our team based in China, we were able to communicate effectively with factories to ensure they could produce the full range of new products, Wombat consisting of; 3,300 pieces of toothbrushes, kitchen products, soap holders and 1,500 pieces of stainless-steel lunchboxes, 1,500 bags, 500 units of pegs and 900 pieces of tins.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/mint-eco-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Mint Eco Shop" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="mint-eco-shop"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default MintEcoShopProject;

export function Head() {
  return (
    <Seo title="Mint Eco Shop | Our Projects | Sourci"
      description="Discover how Sourci managed the full end-to-end supply chain of Mint Eco Shop."
      canonical="/projects/mint-eco-shop" />
  )
}